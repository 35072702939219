import React, { Component } from 'react';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import 'react-table/react-table.css';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import config from '../config';
import PopupAniversariantes from '../components/PopupAniversariantes';
import { asyncForEach } from '../Utils';

class Home extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			modal: { show: false },
			data: []
			
		};
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		
	}


	

	closeModal() {
		// console.log('oi')
		this.setState({ modal: { show: false } });
	}

	async showModal() {
		// e.stopPropagation()
		
				
		this.setState({ modal: { show: true }});
			
	}
	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				
			});
			resolve(data);
		});
	}

	

	componentDidMount() {
		let hoje = moment(new Date())
		let strAuth = JSON.parse(sessionStorage['authSargs']) || JSON.stringify({ authenticated: false, user: 0, ult_login: '2000-01-01' });
		let log = moment(strAuth.ult_login)


		if(hoje.diff(log, 'days') > 0){
			this.showModal()
		}

	}

	

	render() {
		return (
			<div className="boxSite colorSettings">
				<PopupAniversariantes onClose={this.closeModal} showModal={this.state.modal.show} />
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">{/* <h3 className="headerCadastro">Página Inicial</h3> */}</div>
					{/*********************** Filtros ***********************/}
					<div style={{ minHeight: '80vh' }}>
						<div style={{ width: '80vw', marginLeft: '30px', marginRight: '30px' }}>
							<div className="buttonsHome" />
							<LinkContainer to="/sargs/associados">
								<button className="buttonHomeDiv">
									<img
										alt="Associados"
										src={require('../imgs/associados.png')}
										className="buttonHome"
										title="Associados"
									/>
									<br />
									Associados
								</button>
							</LinkContainer>
						</div>
						<div>
							<img alt="Sargs" src={require('../imgs/sargs.png')} title="Sargs" className="logoHome" />
						</div>
					</div>

					{/*********************** Tabela ***********************/}

					{/*********************** Rodapé ***********************/}
				</div>
			</div>
		);
	}
}

export default Home;
