import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown, NavItem, MenuItem } from 'react-bootstrap';
import '../css/Default.css';
import swal from '@sweetalert/with-react';
import PopupAniversariantes from './PopupAniversariantes';
import { asyncForEach } from '../Utils';
import config from '../config';

class NavBar extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			selected: 'Nenhum',
			item: '',
			modal: { show: false },
			data: []
		};
		this.detailItem = this.detailItem.bind(this);
		this.clearItem = this.clearItem.bind(this);
		this.items = this.items.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		
	}

	items(item) {
		if (item !== '') {
			let pos = (item - 1) * 150 + 50;
			return (
				<div className="themeDetailNavBar" style={{ position: 'absolute', left: pos + 'px' }}>
					<a href="#test" className="secondItemNavBar">
						Gerais
					</a>
					<br />
					<a href="#test" className="secondItemNavBar">
						Associação
					</a>
					<br />
					<a href="#test" className="secondItemNavBar">
						Financeiro
					</a>
					<br />
				</div>
			);
		}
	}

	detailItem(e) {
		e.preventDefault();
		this.setState({
			item: e.target.id
		});
	}

	clearItem(e) {
		e.preventDefault();
		this.setState({
			item: ''
		});
	}

	logout(e) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'Ao sair, todas as alterações em andamento serão descartadas. Você tem certeza que deseja sair?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				sessionStorage['authSargs'] = JSON.stringify({ authenticated: false, user: 0 });
				window.location.reload();
			}
		});
	}

	closeModal() {
		// console.log('oi')
		this.setState({ modal: { show: false } });
	}

	async showModal() {
		// e.stopPropagation()
		console.log(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAniversariantes')
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAniversariantes')
			.then((r) => r.json())
			.then(async (r) => {
				let items = await this.modelingData(r);
				
				console.log(items)
				this.setState({ data: items });
				
				this.setState({ modal: { show: true }});
			});
	}
	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				
			});
			resolve(data);
		});
	}

	render() {
		// let detailed = this.items(this.state.item)
		// return (
		//     <div className="themeNavBar" onMouseLeave={this.clearItem}>
		//         <a id='2' className="headItemNavBar" href='#test'>Apergs Web</a>
		//         <a id='2' className="firstItemNavBar" href='#test' onMouseOver={this.detailItem}>Cadastros</a>
		//         <a id='3' className="firstItemNavBar" href='#test' onMouseOver={this.detailItem}>Lançamentos</a>
		//         <a id='4' className="firstItemNavBar" href='#test' onMouseOver={this.detailItem}>Funções Especiais</a>
		//         {detailed}
		//         <a id='exit' className="logOutNavBar" href='#test'>Sair</a>
		//     </div>
		// );

		return (
			<div>
				<PopupAniversariantes onClose={this.closeModal} showModal={this.state.modal.show} data={this.state.data}/>
				<style type="text/css">
					{`
                    .navbar {
                        background-color: var(--navbar-background);
                        z-index: 888
                    }
                    `}
				</style>
				<Navbar inverse collapseOnSelect className="h-20">
					<Navbar.Header>
						<Navbar.Brand>
							<a href="/sargs/home" style={{ display: 'flex' }}>
								<img
									alt="SARGS"
									src={require('../imgs/icontransparent.png')}
									className="iconSARGS"
									title="SARGS"
								/>
								SARGS
							</a>
						</Navbar.Brand>
						<Navbar.Toggle />
					</Navbar.Header>
					<Navbar.Collapse>
						<Nav>
							<NavDropdown eventKey={1} title="Cadastros" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Gerais
								</p>
								<MenuItem eventKey={1.01} href="/sargs/paises">
									Países
								</MenuItem>
								<MenuItem eventKey={1.02} href="/sargs/estados">
									Estados
								</MenuItem>
								<MenuItem eventKey={1.03} href="/sargs/cidades">
									Cidades
								</MenuItem>
								<MenuItem eventKey={1.04} href="/sargs/micro-regioes">
									Micro Regiões
								</MenuItem>
								<MenuItem eventKey={1.05} href="/sargs/parametros">
									Parâmetros
								</MenuItem>
								<MenuItem eventKey={1.06} href="/sargs/associados">
									Associados
								</MenuItem>
								<MenuItem eventKey={1.07} href="/sargs/categorias-associados">
									Categorias de Associados
								</MenuItem>
								<MenuItem eventKey={1.08} href="/sargs/regioes">
									Regiões
								</MenuItem>
								<MenuItem eventKey={1.09} href="/sargs/categorias-arquivos">
									Categorias de Arquivos
								</MenuItem>
								<MenuItem eventKey={1.10} href="/sargs/universidades">
									Universidades
								</MenuItem>
								<MenuItem eventKey={1.11} href="/sargs/centro-especializacao">
									Centros de Especialização
								</MenuItem>
								<MenuItem eventKey={1.12} href="/sargs/ligas">
									Ligas
								</MenuItem>
								<MenuItem eventKey={1.13} href="/sargs/sociedades">
									Sociedades
								</MenuItem>
								<MenuItem eventKey={1.14} href="/sargs/tipos-sociedades">
									Tipos Sociedades
								</MenuItem>
								<MenuItem eventKey={1.13} href="/sargs/area-atuacao">
									Área de Atuação
								</MenuItem>
							</NavDropdown>
							<NavDropdown eventKey={2} title="Lançamentos" id="basic-nav-dropdown">
								<MenuItem eventKey={2.1} href="/sargs/anuidades">
									Anuidades
								</MenuItem>
								<MenuItem eventKey={2.1} href="/sargs/retornos-unicred">
									Retornos - Unicred
								</MenuItem>
							</NavDropdown>
							<NavDropdown eventKey={3} title="Relatórios" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Gerenciais
								</p>
								<MenuItem eventKey={3.1} href="/sargs/relatorios/listagem-associados">
									Listagem de Associados
								</MenuItem>
								<MenuItem eventKey={3.2} href="/sargs/relatorios/listagem-associados-cet">
									Listagem de Associados por CET
								</MenuItem>
								<MenuItem eventKey={3.3} href="/sargs/relatorios/listagem-ligas">
									Listagem de Ligas
								</MenuItem>
								<MenuItem eventKey={3.4} href="/sargs/relatorios/listagem-sociedades">
									Listagem de Sociedades
								</MenuItem>
								<MenuItem eventKey={3.5} href="/sargs/relatorios/socios-remidos">
									Sócios a se tornar Remidos
								</MenuItem>
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Financeiros
								</p>
								<MenuItem eventKey={3.6} href="/sargs/relatorios/recebimento-socios">
									Recebimentos de Sócios
								</MenuItem>
								<MenuItem eventKey={3.7} href="/sargs/relatorios/socios-em-dia">
									Sócios Adimplentes/Inadimplentes
								</MenuItem>
							</NavDropdown>
							<NavDropdown eventKey={4} title="Funções Especiais" id="basic-nav-dropdown">
								<p
									style={{
										fontWeight: 'bold',
										textDecoration: 'underline',
										textAlign: 'left',
										marginLeft: '20px',
										color: '#9d9d9d'
									}}
								>
									Sistema
								</p>
								<MenuItem eventKey={4.1} href="/sargs/usuarios">
									Usuários
								</MenuItem>
								<MenuItem onClick={this.showModal}>
									Aniversariantes
								</MenuItem>
							</NavDropdown>
						</Nav>
						<Nav pullRight>
							<NavItem eventKey={1} onClick={this.logout}>
								Sair
							</NavItem>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}

export default NavBar;
