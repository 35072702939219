

import { Modal } from 'react-bootstrap';
import { asyncForEach } from '../Utils';
import ReactTable from 'react-table';
import config from '../config';
import { useEffect, useState } from 'react';
import "../css/Table.css"
import Icon from 'react-icons-kit';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';



export default function PopupAniversariantes(props){
    const [data, setData] = useState([])
    const [diaIni, setDiaIni] = useState()
    const [mesIni, setMesIni] = useState()
    const [diaFim, setDiaFim] = useState()
    const [mesFim, setMesFim] = useState()

    useEffect(() => {
        getData()
    }, [])

    async function getData(e) {
		e?.stopPropagation()
        e?.preventDefault()
        let query = '?buscar=true'
        
        if (diaIni){
            query = query + '&diaIni='+diaIni
        }
        if (diaFim){
            query = query + '&diaFim='+diaFim
        }
        if (mesIni){
            query = query + '&mesIni='+mesIni
        }
        if (mesFim){
            query = query + '&mesFim='+mesFim
        }
		console.log(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAniversariantes'+query)
		fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAniversariantes'+query)
			.then((r) => r.json())
			.then(async (r) => {
				let items = await modelingData(r);
				
				console.log(items)
				setData(items);
			});
	}
	async function modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				
			});
			resolve(data);
		});
	}

    async function exportaData() {
		let columns = [{
			align:1,
			field: "nome",
			fk: {},
			selected: true,
			value: "Nome",
			width: 200
		},
		{
			align:1,
			field: "email",
			fk: {},
			selected: true,
			value: "E-mail",
			width: 200
		},
		{
			align:1,
			field: "telefone_celular",
			fk: {},
			selected: true,
			value: "Telefone Celular",
			width: 200
		}]

		let exportData = {columns: columns, data: data};

		await fetch(
			config.protocol +
				'://' +
				config.server +
				':' +
				config.portBackend +
				'/api/exportacaoLivre?table=associados',
			{
				method: 'POST',
				headers: {
					'Content-type': 'application/json'
				},
				body: JSON.stringify(exportData)
			}
		).then((r) => r.blob())
		.then((r) => {
			var url = window.URL.createObjectURL(r);
			var a = document.createElement('a');
			a.href = url;
			a.download = "exportacao.xlsx";
			document.body.appendChild(a);
			a.click();    
			a.remove();
		});	
	}

	function modalState() {
		if (props.showModal === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}
    const dias = listaDias()

    function listaDias() {
		let listaAnos = [];
		for (let i = 1; i <= 31; i++) {
			listaAnos.push(i);
		}
		return listaAnos;
    }

    return (
    
        <div className={props.showModal ? 'ModalShow' : 'ModalHide' } tabIndex="-1">
            <Modal.Dialog className="Modal">
                <div>
                <Modal.Header className="ModalBg">
                        <div className="ModalHeader">
                            <h3 className="headerModal">Associado(s) Aniversariante(s)</h3>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="ModalBg">
                        <div className="ModalBody" style={{display: "flex", flexDirection: "column"}}>
                        
                            <form style={{display: "flex", width: '100%', display:'flex'}}>
                                <div style={{gap: 20, }}>
                                    <label className="labelFiltro">Mes Inicial</label>
                                    <select
                                        name="mesIni"
                                        id="mesIni"
                                        style={{width: 150}}
                                        className="selectFiltro"
                                        value={mesIni || ''}
                                        onChange={(e) => setMesIni(e.target.value)}
                                    >
                                        <option value="">Mes</option>
                                        <option value="01">Janeiro</option>
                                        <option value="02">Fevereiro</option>
                                        <option value="03">Março</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Maio</option>
                                        <option value="06">Junho</option>
                                        <option value="07">Julho</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Setembro</option>
                                        <option value="10">Outubro</option>
                                        <option value="11">Novembro</option>
                                        <option value="12">Dezembro</option>
                                    </select>
                                    <label className="labelFiltro">Dia Inicial</label>
                                    <select
                                        name="diaIni"
                                        id="diaIni"
                                        className="selectFiltro"
                                        value={diaIni || ''}
                                        style={{width: 150}}
                                        onChange={(e) => setDiaIni(e.target.value)}
                                    >
                                        <option value="">Dia</option>
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                    </select>

                                </div>
                                <div >
                                    <label className="labelFiltro">Mes Final</label>
                                    <select
                                        name="mesFim"
                                        id="mesFim"
                                        style={{width: 150}}
                                        className="selectFiltro"
                                        value={mesFim || ''}
                                        onChange={(e) => setMesFim(e.target.value)}
                                    >
                                        <option value="">Mes</option>
                                        <option value="01">Janeiro</option>
                                        <option value="02">Fevereiro</option>
                                        <option value="03">Março</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Maio</option>
                                        <option value="06">Junho</option>
                                        <option value="07">Julho</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Setembro</option>
                                        <option value="10">Outubro</option>
                                        <option value="11">Novembro</option>
                                        <option value="12">Dezembro</option>
                                    </select>
                                    <label className="labelFiltro">Dia Final</label>
                                    <select
                                        name="diaFim"
                                        id="diaFim"
                                        style={{width: 150}}
                                        className="selectFiltro"
                                        value={diaFim || ''}
                                        onChange={(e) => setDiaFim(e.target.value)}
                                    >
                                        <option value="">Dia</option>
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                    </select>
                                </div>
                                <div style={{display: 'flex', flex: 1, alignItems: 'center' }}>
                                    <button style={{height: 30, backgroundColor: '#FEFEFE', color: 'black'}} className="buttonFiltroProcessar" onClick={getData}>
                                        <Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
                                    </button>

                                </div>
                            </form>
                            <div className="divTabela">
                                <ReactTable
                                    data={data}
                                    previousText="Anterior"
                                    nextText="Próximo"
                                    loadingText="Carregando..."
                                    pageText="Página"
                                    ofText="de"
                                    rowsText="registros"
                                    noDataText="Nenhum registro encontrado"
                                    columns={[
                                        {
                                            Header: 'Associado',
                                            accessor: 'nome',
                                            width: 120
                                        },
                                        {
                                            Header: 'Telefone',
                                            accessor: 'telefone_celular',
                                            width: 110
                                        },
                                        {
                                            Header: 'E-mail',
                                            accessor: 'email',
                                            width: 240
                                        },
                                    ]}
                                    defaultSorted={[
                                        {
                                            id: 'nome',
                                            desc: false
                                        }
                                    ]}
                                    defaultPageSize={10}
                                    className="-striped -highlight"
                                    
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    
                    <Modal.Footer className="ModalBg">
                        <div className="ModalFooter">
                            <button className="ModalButton" onClick={exportaData}>
                                Exportar
                            </button>
                            <button className="ModalButton" onClick={props.onClose}>
                                Fechar
                            </button>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal.Dialog>
        </div>)
}