import React, { Component } from 'react';
import config from '../config';
import NavBar from '../components/NavBar';
import '../css/Default.css';
import Autocomplete from '../components/Autocomplete';
import { LinkContainer } from 'react-router-bootstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Modal } from 'react-bootstrap';
import { garanteDate, asyncForEach, getParameterByName } from '../Utils';
import MultiSelect from "@khanacademy/react-multi-select";
import {fileExcel} from 'react-icons-kit/icomoon/fileExcel'
// import moment from 'moment';
import swal from '@sweetalert/with-react';
import { Icon } from 'react-icons-kit';
import { edit } from 'react-icons-kit/ionicons/edit';
import { iosTrash } from 'react-icons-kit/ionicons/iosTrash';
import { ic_add_circle } from 'react-icons-kit/md/ic_add_circle';
import { iosSearchStrong } from 'react-icons-kit/ionicons/iosSearchStrong';
import { ic_clear } from 'react-icons-kit/md/ic_clear';



class Associados extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			combos: [],
			teste: [],
			columns: [],
			filter: {
			},
			modal: { show: false },
			selected: [],
			categoriasAssociados: [],
			filterChanged: '',
			data: []
		};
		this.getData = this.getData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.filterData = this.filterData.bind(this);
		this.handleClean = this.handleClean.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.filterCidade = this.filterCidade.bind(this);
		this.filterRegiao = this.filterRegiao.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.modalState = this.modalState.bind(this);
		this.exportacaoSelectAll = this.exportacaoSelectAll.bind(this);
		this.handleExport = this.handleExport.bind(this);
		this.toggleColumn = this.toggleColumn.bind(this);
		this.populaSelectedColumns = this.populaSelectedColumns.bind(this);
		this.populaUnselectedColumns = this.populaUnselectedColumns.bind(this);
	}


	toggleColumn(e, name) {
		e.preventDefault();
		let columns = this.state.columns;
		asyncForEach(columns, (item, index) => {
			if (item.value === name) item.selected = !item.selected
			if (index+1 === columns.length) this.setState({columns: columns})
		})	
	}

	populaSelectedColumns(item, id) {
		if (item.selected) {
			return (
				<li name={item.value} onClick={(e)=>this.toggleColumn(e,item.value)}>
					{item.value}
				</li>
			);
		}
	}

	populaUnselectedColumns(item, id) {
		if (!item.selected) {
			return (
				<li name={item.value} onClick={(e)=>this.toggleColumn(e,item.value)}>
					{item.value}
				</li>
			);
		}
		
	}

	exportacaoSelectAll(e,select) {
		e.preventDefault();
		let columns = this.state.columns
		asyncForEach(columns, (item, index)=>{
			item.selected = select
			if (index+1 === columns.length) this.setState({columns: columns})
		})
	}

	async handleExport () {
		let columns = this.state.columns;
		let selected = columns.filter((item) => {return item.selected === true}).length > 0;
		let data = this.state.data;
		let haveData = data.length > 0;
		console.log(selected)
		let exportData = {columns: columns, data: data};
		console.log(exportData)
		if (!selected) swal('Nenhuma coluna selecionada!!', 'Nenhuma coluna foi selecionada. Selecione pelo menos uma para exportar os registros!', 'error');
		if (!haveData) swal('Nenhum registro encontrado!!', 'Nenhum registro filtrado!! Filtre ao menos um registro para gerar a exportação.', 'error');
		if (haveData && selected) {
			await fetch(
				config.protocol +
					'://' +
					config.server +
					':' +
					config.portBackend +
					'/api/exportacaoLivre?table=associados',
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(exportData)
				}
			).then((r) => r.blob())
			.then((r) => {
				var url = window.URL.createObjectURL(r);
				var a = document.createElement('a');
				a.href = url;
				a.download = "exportacao.xlsx";
				document.body.appendChild(a);
				a.click();    
				a.remove();
			});	
		}
	}

	
	closeModal() {
		this.setState({ modal: { show: false } });
	}

	async showModal(e, codigo) {
		// e.stopPropagation()
		e.preventDefault();
		this.setState({ modal: { show: true }});
	}

	modalState() {
		if (this.state.modal.show === true) {
			return 'ModalShow';
		} else {
			return 'ModalHide';
		}
	}
	// populaLotacao(item, id) {
	// 	if (item.type === 'lotacao') {
	// 		return (
	// 			<option value={item.value} key={id}>
	// 				{item.display}
	// 			</option>
	// 		);
	// 	}
	// }

	async componentDidMount() {
		//Busca valores para combo de filtro
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCidades')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCidades = this.state.combos;
			await asyncForEach(r, async (item) => {
				let cidades = {
					type: 'cidades',
					display: item.nome,
					value: item.pk_cid
				};
				await combosCidades.push(cidades);
			});
			this.setState({ combos: this.state.combos });
		});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getRegioes')
			.then((r) => r.json())
			.then(async (r) => {
				let combosRegioes = this.state.combos;
				await asyncForEach(r, async (item) => {
					let regioes = {
						type: 'regioes',
						display: item.descricao,
						value: item.pk_reg
					};
					await combosRegioes.push(regioes);
				});
				this.setState({ combos: this.state.combos });
			});

		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCategoriasAssociados')
		.then((r) => r.json())
		.then(async (r) => {
			let combosCategoriasAssociados = this.state.combos;
			await asyncForEach(r, async (item) => {
				let categoriasAssociados = {
					type: 'categoriasAssociados',
					display: item.descricao,
					label: item.descricao,
					value: item.pk_cat
				};
				await combosCategoriasAssociados.push(categoriasAssociados);
			});
			let categoriasAssociados = {
				type: 'categoriasAssociados',
				display: 'SEM CATEGORIA',
				label: 'SEM CATEGORIA',
				value: null
			};
			await combosCategoriasAssociados.push(categoriasAssociados);
			this.setState({ combos: this.state.combos });
		});

		//Carregar Parâmetros de pesquisa
		let query = {};
		query.filtered = getParameterByName('filtered');
		query.nome = getParameterByName('nome');
		query.matricula_sba = getParameterByName('matricula_sba');
		query.numero_crm = getParameterByName('numero_crm');
		query.fk_cid_resid = Number(getParameterByName('fk_cid_resid'));
		query.cidade = this.state.combos.filter((item) => {
			return item.type === 'cidades' && Number(item.value) === query.fk_cid_resid;
		})[0];
		query.fk_reg = Number(getParameterByName('fk_reg'));
		query.regiao = this.state.combos.filter((item) => {
			return item.type === 'regioes' && Number(item.value) === query.fk_reg;
		})[0];
		query.listaFkCat = []
		query.fk_cats = getParameterByName('fk_cats')
		console.log(query.fk_cats)
		if (query.fk_cats) {
			console.log('teste')
			query.categoriasAssociados = this.state.combos.filter((item) => {
				if (item.type === 'categoriasAssociados' && (JSON.parse('['+query.fk_cats+']')).includes(item.value)){
					query.listaFkCat.push(item.value)
					return true
				} else return false
			});
		} else {
			console.log('teste2')
			query.categoriasAssociados = this.state.combos.filter((item) => {
				if (item.type === 'categoriasAssociados'){
					query.listaFkCat.push(item.value)
					return true
				} else return false
			});
		}

		

		this.setState({
			filter: query,
			categoriasAssociados: query.listaFkCat
		});

		//Caso filtered=true, traz form já processado
		var event = new Event('build');
		if (query.filtered) this.getData(event);
	}

	handleDelete(e, pk) {
		e.preventDefault();
		swal({
			dangerMode: true,
			title: 'Atenção!',
			text: 'O registro selecionado será excluído. Confirma?',
			buttons: [ 'Sim', 'Não' ]
		}).then((result) => {
			if (!result) {
				//Delete
				fetch(
					config.protocol +
						'://' +
						config.server +
						':' +
						config.portBackend +
						'/api/deleteAssociados?pk=' +
						pk,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json'
						},
						body: JSON.stringify({})
					}
				)
					.then((r) => r.json())
					.then((r) => {
						console.log(r);
						if (r.message === 'Success!') {
							swal('Exlusão realizada', 'Registro excluído com sucesso!', 'success').then((result) => {
								//Caso filtered=true, traz form já processado
								var event = new Event('build');
								this.getData(event);
							});
						} else {
							swal('Exclusão não realizada', 'Registro não foi excluído. Verifique os campos.', 'error');
						}
					});
			}
		});
	}

	

	filterCidade(item) {
		let newFilter = this.state.filter;
		newFilter.fk_cid_resid = item.value;
		newFilter.cidade = item;
		this.setState({ filter: newFilter });
	}

	filterRegiao(item) {
		let newFilter = this.state.filter;
		newFilter.fk_reg = item.value;
		newFilter.regiao = item;
		this.setState({ filter: newFilter });
	}

	// filterCategoriaAssociados(item) {
	// 	let newFilter = this.state.filter;
	// 	newFilter.fk_teste = item.value;
	// 	newFilter.categoriasAssociados = item;
	// 	this.setState({ filter: newFilter });
	// }

	handleChange(e) {
		//Popula inputs do filtro
		e.preventDefault();
		let target = e.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		let name = target.name;
		let reg = this.state.filter;
		reg[name] = value.toUpperCase();
		this.setState({
			filter: reg
		});
	}

	handleClean(e) {
		//Limpa o filtro
		e.preventDefault();
		console.log('limpa');
		window.history.replaceState({ filtered: false }, 'filter', '/sargs/associados'); //Apaga QueryURL
		let todasCategorias = []
		this.state.combos.forEach((item) => {
			if (item.type === 'categoriasAssociados') todasCategorias.push(item.value)
		});
		this.setState({ filter: [], categoriasAssociados: todasCategorias });
	}

	async modelingData(data) {
		//Trata os campos
		return new Promise(async (resolve) => {
			await asyncForEach(data, async (item) => {
				item.data_nasc = garanteDate(item.data_nasc);
			});
			resolve(data);
		});
	}

	async filterData(data) {
		//Filtra os dados
		return new Promise(async (resolve) => {
			let filter = this.state.filter;

			// Filtro Especial: Celulares (Lista de FK)
			let listafkcel = [];
			let listaFkCat = []
			if (filter.celular) {
				let celulares = [];

				//Celulares
				await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getCelulares')
					.then((r) => r.json())
					.then(async (r) => {
						celulares = r.filter((item) => {
							return item.numero.includes(filter.celular);
						});
						await asyncForEach(celulares, async (item) => {
							listafkcel.push(item.fk_ass);
						});
					});
			}

			await asyncForEach(this.state.categoriasAssociados, async (item) => {
				listaFkCat.push(Number(item));
			});

			// let filtered = await data.filter((item, index) => {
			// 	//Filtro: Nome
			// 	let nome = (item.nome || '').includes((filter.nome || '').toUpperCase());

			// 	//Filtro: Cpf
			// 	let cpf = (item.cpf || '').includes((filter.cpf || '').toUpperCase());

			// 	//Filtro: Rg
			// 	let rg = (item.rg || '').includes((filter.rg || '').toUpperCase());

			// 	//Filtro: Matrícula SBA
			// 	let matricula_sba = true; 
			// 	// (item.matricula_sba || '').includes((filter.matricula_sba || '').toUpperCase());

			// 	//Filtro: Número CRM
			// 	let numero_crm = true
			// 	// (item.numero_crm || '').includes((filter.numero_crm || '').toUpperCase());

			// 	let fk_cid_resid = Number(item.fk_cid_resid) === Number(filter.fk_cid_resid) || (filter.fk_cid_resid || '') === '';

			// 	let fk_reg = Number(item.fk_reg) === Number(filter.fk_reg) || (filter.fk_reg || '') === '';

			// 	let fk_cat = listaFkCat.includes(Number(item.fk_cat));
			// 	// console.log(item.fk_cat, listaFkCat, fk_cat)
			// 	//Filtra
			// 	if (item.pk_ass === 1345) console.log(nome, cpf, rg, matricula_sba, numero_crm, fk_cid_resid, fk_reg, fk_cat)
			// 	return nome && cpf && rg && matricula_sba && numero_crm && fk_cid_resid && fk_reg && fk_cat;
			// });

			let queryString = '?';

			if (filter.nome) {
				if (queryString === '?') {
					queryString = queryString + 'nome=' + filter.nome;
				} else queryString = queryString + '&nome=' + filter.nome;
			}

			if (filter.cpf) {
				if (queryString === '?') {
					queryString = queryString + 'cpf=' + filter.cpf;
				} else queryString = queryString + '&cpf=' + filter.cpf;
			}

			if (filter.rg) {
				if (queryString === '?') {
					queryString = queryString + 'rg=' + filter.rg;
				} else queryString = queryString + '&rg=' + filter.rg;
			}

			if (filter.matricula_sba) {
				if (queryString === '?') {
					queryString = queryString + 'matricula_sba=' + filter.matricula_sba;
				} else queryString = queryString + '&matricula_sba=' + filter.matricula_sba;
			}

			if (filter.numero_crm) {
				if (queryString === '?') {
					queryString = queryString + 'numero_crm=' + filter.numero_crm;
				} else queryString = queryString + '&numero_crm=' + filter.numero_crm;
			}

			if (filter.fk_cid_resid) {
				if (queryString === '?') {
					queryString = queryString + 'fk_cid_resid=' + filter.fk_cid_resid;
				} else queryString = queryString + '&fk_cid_resid=' + filter.fk_cid_resid;
			}

			if (filter.fk_reg) {
				if (queryString === '?') {
					queryString = queryString + 'fk_reg=' + filter.fk_reg;
				} else queryString = queryString + '&fk_reg=' + filter.fk_reg;
			}

			if (listaFkCat[0]) {
				if (queryString === '?') {
					queryString = queryString + 'fk_cats=' + listaFkCat;
				} else queryString = queryString + '&fk_cats=' + listaFkCat;
			}

			//Monta Query
			if (queryString !== '?') {
				window.history.replaceState(
					{ filtered: true },
					'filter',
					'/sargs/associados' + queryString + '&filtered=true'
				);
			} else {
				window.history.replaceState({ filtered: true }, 'filter', '/sargs/associados?filtered=true');
			}

			resolve(queryString);
		});
	}

	async getData(e) {
		//Busca, filtra e trata os dados
		e.preventDefault();
		
		let query = await this.filterData();
		console.log('query:', query)

		//Busca
		await fetch(config.protocol + '://' + config.server + ':' + config.portBackend + '/api/getAssociados'+query)
			.then((r) => r.json())
			.then(async (r) => {
				let colunasExportacao = [
					{value: 'Código', field: 'pk_ass', fk: {}, selected: false, width: 50, align: 3},
					{value: 'Nome', field: 'nome', fk: {}, selected: false, width: 200, align: 1},
					{value: 'CRM', fk: {}, field: 'numero_crm', selected: false, width: 100, align: 3},
					{value: 'Matrícula SBA', fk: {}, field: 'matricula_sba', selected: false, width: 100, align: 3},
					{value: 'Email', fk: {}, field: 'email', selected: false, width: 100, align: 3},
					{value: 'RG', fk: {}, field: 'rg', selected: false, width: 100, align: 3},
					{value: 'Data Nascimento', fk: {}, field: 'data_nasc', selected: false, width: 100, align: 3},
					{value: 'Categoria', fk: {fkField: 'fk_cat', tablePk: 'pk_ass', tableField: 'descricao',table: 'categorias_associados'}, field: 'nomecat', selected: false, width: 150, align: 1},
					{value: 'Regiao', fk: {fkField: 'fk_reg', tablePk: 'pk_ass', tableField: 'descricao',table: 'regioes'}, field: 'nomereg', selected: false, width: 150, align: 1},
					{value: 'CPF', fk: {}, field: 'cpf', selected: false, width: 100, align: 1},
					{value: 'CEP', fk: {}, field: 'cep_resid', selected: false, width: 100, align: 1},
					{value: 'Endereço Residencial', field: 'endereco_resid', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Bairro Residencial', field: 'bairro_resid', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Cidade Residencial', fk: {fkField: 'fk_cid_resid', tablePk: 'pk_ass', tableField: 'nome',table: 'cidades'}, field: 'nomecid_resid', selected: false, width: 150, align: 1},
					{value: 'Endereço Comercial', field: 'endereco_com', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Bairro Comercial', field: 'bairro_com', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Cidade Comercial', fk: {fkField: 'fk_cid_com', tablePk: 'pk_ass', tableField: 'nome',table: 'cidades'}, field: 'nomecid_com', selected: false, width: 150, align: 1},
					{value: 'CET', fk: {fkField: 'fk_cet', tablePk: 'pk_cet', tableField: 'descricao',table: 'centro_especializacao'}, field: 'nomecet', selected: false, width: 150, align: 1},
					{value: 'Telefone Residencial', field: 'telefone_resid', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Telefone Celular', field: 'telefone_celular', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Telefone Comercial', field: 'telefone_comercial', fk: {}, selected: false, width: 200, align: 1},
					{value: 'Telefone Hospitalar', field: 'telefone_hospitalar', fk: {}, selected: false, width: 200, align: 1},
					// {value: 'Situação', field: 'situacao', fk: {fkField: 'fk_ass', tablePk: 'pk_ass', tableField: 'fk_ass',table: 'anuidade'}, selected: false, width: 80, align: 1}
				];
				//Filtra
				// let items = 
				//Trata
				
				let items = await this.modelingData(r);
				console.log(items)
				this.setState({ data: items, columns: colunasExportacao });
			});
	}

	render() {
		let cidades = this.state.combos.filter((item) => {
			return item.type === 'cidades';
		});
		let regioes = this.state.combos.filter((item) => {
			return item.type === 'regioes';
		});
		let categoriasAssociados = this.state.combos.filter((item) => {
			return item.type === 'categoriasAssociados';
		});
		let selectedColumns = this.state.columns.map(this.populaSelectedColumns)
		let unselectedColumns = this.state.columns.map(this.populaUnselectedColumns)
		// let {selectedCategoriaAssociados} = this.state.filter.
		// let lotacoes = this.state.combos.map(this.populaLotacao);
		return (
			<div className="boxSite colorSettings">
				{/***************** Barra de Navegação *******************/}
				<div className="boxNavBar">
					<NavBar selected="Associados" />
				</div>
				{/***************** Tela do WebSite *******************/}
				<div className="boxTela">
					{/*********************** Header ***********************/}
					<div className="boxHeader">
						<h3 className="headerCadastro">Cadastro de Associados</h3>
					</div>
					{/*********************** Filtros ***********************/}
					<form className="boxFiltros">
						{/* Parâmetros de pesquisa:<br/> */}
						<div className="filtros">
							<div className="column-filter">
							<div className="itemFiltro">
									<label className="labelFiltro">Nome</label>
									<input
										name="nome"
										type="text"
										id="filtroNome"
										className="inputFiltro"
										style={{ width: '50vw' }}
										value={this.state.filter.nome || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">CPF</label>
									<input
										name="cpf"
										type="text"
										id="filtroCpf"
										className="inputFiltro"
										style={{ width: '110px' }}
										value={this.state.filter.cpf || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">RG</label>
									<input
										name="rg"
										type="text"
										id="filtroRg"
										className="inputFiltro"
										style={{ width: '110px' }}
										value={this.state.filter.rg || ''}
										onChange={this.handleChange}
									/>
								</div>
								<br />
								<div className="itemFiltro">
									<label className="labelFiltro">Número CRM</label>
									<input
										name="numero_crm"
										type="text"
										id="filtroNumero_crm"
										className="inputFiltro"
										style={{ width: '100px' }}
										value={this.state.filter.numero_crm || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro">
									<label className="labelFiltro">Matrícula SBA</label>
									<input
										name="matricula_sba"
										type="text"
										id="filtroMatricula_sba"
										className="inputFiltro"
										style={{ width: '100px' }}
										value={this.state.filter.matricula_sba || ''}
										onChange={this.handleChange}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '200px' }}>
									<label className="labelFiltro">Cidade</label>
									<Autocomplete
										field="filtroFk_cid"
										items={cidades}
										inModal=""
										selected={this.state.filter.cidade || { display: '' }}
										select={this.filterCidade}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '200px' }}>
									<label className="labelFiltro">Região</label>
									<Autocomplete
										field="filtroFk_reg"
										items={regioes}
										inModal=""
										selected={this.state.filter.regiao || { display: '' }}
										select={this.filterRegiao}
									/>
								</div>
								<div className="itemFiltro" style={{ width: '400px' }}>
									<label className="labelFiltro">Categorias de Associados</label>
									<MultiSelect
										options={categoriasAssociados}
										selected={this.state.categoriasAssociados}
										onSelectedChanged={selected => this.setState({categoriasAssociados: selected})}
										className="multiSelectFiltro"
										overrideStrings={{
											selectSomeItems: "Selecione um ou mais itens...",
											allItemsAreSelected: "Todos",
											selectAll: "SELECIONAR TODOS",
											search: "Pesquisar",
										}}
									/>
								</div>

							</div>
							<br />
							<div className="column-filter-2">
								<button className="buttonFiltroProcessar" onClick={this.getData}>
									<Icon size={20} style={{ display: 'inline' }} icon={iosSearchStrong} />Processar
								</button>
								<button className="buttonFiltroLimpar" onClick={this.handleClean}>
									<Icon size={20} style={{ display: 'inline' }} icon={ic_clear} />Limpar
								</button>
								<LinkContainer to={'/sargs/associados/registro'}>
									<button className="buttonNovo">
										<Icon size={20} style={{ display: 'inline' }} icon={ic_add_circle} />Novo
										Registro
									</button>
								</LinkContainer>
								<button className="buttonExportacao"  onClick={this.showModal}>
									<Icon size={16} style={{ display: 'inline' }} icon={fileExcel} />Exportação Livre
								</button>
							</div>
						</div>
					</form>
					{/*********************** Tabela ***********************/}
					<div style={{ paddingBottom: '30px' }}>
						<div style={{ marginLeft: '30px', marginTop: '30px', marginRight: '30px' }}>
						<div className={this.modalState()} tabIndex="-1">
								<Modal.Dialog className="Modal">
									<div>
										<Modal.Header className="ModalBg">
											<div className="ModalHeader">
												<h3 className="headerModal">Exportação Livre - Associados</h3>
											</div>
										</Modal.Header>
										<Modal.Body className="ModalBg">
											<div className="ModalBody">
												<form
													id="exportacaoAssociados"
													name="exportacaoAssociados"
													onSubmit={this.submitData}
												>
													<div className="containerColumns rowColumns">
														<div className="itemColumns">
															<label className="labelColumns">Colunas disponíveis</label>
															<ul name='unselected' className='ListSelect'>
																{unselectedColumns}
															</ul>																												
														</div>
														<div name='selected' className="itemColumns">
															<label className="labelColumns">Colunas selecionadas</label>
															<ul className='ListSelect'>
																{selectedColumns}
															</ul>
														</div>
													</div>
													<div className="containerColumns rowColumns">
														<div className="itemColumns">
															<button className="ModalButtonColumns" onClick={(e) => this.exportacaoSelectAll(e,true)}>Selecionar Todos</button>
														</div>
														<div className="itemColumns">
															<button className="ModalButtonColumns" onClick={(e) => this.exportacaoSelectAll(e,false)}>Limpar seleção</button>
														</div>
													</div>
												</form>
											</div>
										</Modal.Body>
										<Modal.Footer className="ModalBg">
											<div className="ModalFooter">
												<button className="ModalButton" onClick={this.handleExport}>
													Exportar
												</button>
												<button className="ModalButton" onClick={this.closeModal}>
													Fechar
												</button>
											</div>
										</Modal.Footer>
									</div>
								</Modal.Dialog>
							</div>
							
							<div className="divTabela">
								<ReactTable
									data={this.state.data}
									previousText="Anterior"
									nextText="Próximo"
									loadingText="Carregando..."
									pageText="Página"
									ofText="de"
									rowsText="registros"
									noDataText="Nenhum registro encontrado"
									columns={[
										{
											Header: 'Associado',
											accessor: 'nome',
											width: 420
										},
										{
											Header: 'CPF',
											accessor: 'cpf',
											width: 110
										},
										{
											Header: 'RG',
											accessor: 'rg',
											width: 110
										},
										{
											Header: 'Dt Nasc',
											accessor: 'data_nasc',
											width: 90
										},
										{
											Header: 'Telefone',
											accessor: 'telefone_celular',
											width: 90
										},
										{
											Header: 'Categoria',
											accessor: 'nomecat',
											width: 90
										},
										{
											Header: 'CET',
											accessor: 'nomecet',
											width: 90
										},
										{
											Header: 'Cidade',
											accessor: 'nomecid',
											width: 150
										},
										{
											Header: 'Situação',
											accessor: 'situacao',
											width: 80
										},
										{
											Header: 'Código',
											accessor: 'pk_ass',
											show: false
										}
									]}
									defaultSorted={[
										{
											id: 'nome',
											desc: false
										}
									]}
									defaultPageSize={10}
									className="-striped -highlight"
									SubComponent={(row) => {
										return (
											<div className="buttonsDetail">
												<LinkContainer to={'/sargs/associados/registro?pk=' + row.row.pk_ass}>
													<button className="buttonDetail">
														<Icon
															size={20}
															icon={edit}
															style={{ marginRight: '5px', marginLeft: '-5px' }}
														/>Editar
													</button>
												</LinkContainer>
												<button
													className="buttonDetail"
													onClick={(e) => {
														this.handleDelete(e, row.row.pk_ass);
													}}
													name={row.row.pk_ass}
												>
													<Icon
														size={20}
														icon={iosTrash}
														style={{ marginRight: '5px', marginLeft: '-5px' }}
													/>Excluir
												</button>
											</div>
										);
									}}
								/>
							</div>
							<p style={{float: 'right'}}>{this.state.data.length+' registros encontrados'}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Associados;
